import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ProductHeader from "../../../components/Products/Header";
import ProductDetail from "../../../components/Products/Detail";
import Layout from "../../../templates/layout";

import { productByCephalopods } from "../../../constants";

const CurrentIndex = 0;
const CurrentTrans = `productsLists.cephalopods.${productByCephalopods[CurrentIndex].data.link}`

const Detail = () => {
  const { t } = useTranslation();

  const productName = t(`${CurrentTrans}.title`);

  return (
    <>
      <Helmet>
        <title>{`${productName} | ${t(
          "meta.title"
        )}`}</title>
        <meta content={productByCephalopods[CurrentIndex].alt} name="description" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Layout
        currentLink="product/cephalopods/octopus"
        description={productByCephalopods[CurrentIndex].alt}
      >
        <>
          <ProductHeader
            title={productName}
            linkTitle={productName
              .replaceAll(" ", "-")
              .toLowerCase()}
            isProduct
            isTitleH1
          />
          <ProductDetail
            image={productByCephalopods[CurrentIndex].src}
            altImages={productByCephalopods[CurrentIndex].alt}
            productName={productName}
            productDesc={t(`${CurrentTrans}.desc`)}
            tags={productByCephalopods[CurrentIndex].data.tags}
          />
        </>
      </Layout>
    </>
  );
};

export default Detail;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
